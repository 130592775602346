import useIntegrations from "#/hooks/data/use-integrations";
import { ThemeContext } from "#/providers/theme-provider";
import { FEATURE_FLAGS, POS } from "#/utils/platform.utils";
import { useContext } from "react";

export const useEnabledAI = () => {
  const { pos } = useIntegrations();

  const theme = useContext(ThemeContext);

  return (
    theme?.flags?.aiEnabled ||
    FEATURE_FLAGS.AI_DESCRIPTIONS.includes(pos?.source as POS)
  );
};
