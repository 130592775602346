// @ts-check
import { z } from "zod";

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  EDGE_CONFIG: z.string().min(1), // edge config
  REVALIDATION_TOKEN: z.string().min(1), // revalidation token
  AWS_REGION: z.string().optional(), // aws region for bedrock
  AWS_ACCESS_KEY_ID: z.string().min(1), // aws access key for v2 bedrock
  AWS_SECRET_ACCESS_KEY: z.string().min(1), // aws secret access key for v2 bedrock
  TINYBIRD_READER_TOKEN: z.string().min(1), // tinybird secret for analytics
});

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const doesNotEndInSlash = /[^\/]$/;
export const clientSchema = z.object({
  NEXT_PUBLIC_GOOGLE_PLACES_KEY: z.string(),
  NEXT_PUBLIC_BASE_URL: z
    .string()
    .url()
    .regex(doesNotEndInSlash, "URL can not end with a slash"),
  NEXT_PUBLIC_OAUTH_GATEWAY_URL: z
    .string()
    .url()
    .regex(doesNotEndInSlash, "URL can not end with a slash"),
  NEXT_PUBLIC_ENV: z.string(), // dev_unstable or production
  NEXT_PUBLIC_APP: z.string(), // hooked or whatever
  NEXT_PUBLIC_AI_ROUTE: z.string().optional().default("/api/ai"),
});

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
  NEXT_PUBLIC_GOOGLE_PLACES_KEY: process.env.NEXT_PUBLIC_GOOGLE_PLACES_KEY,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_APP: process.env.NEXT_PUBLIC_APP,
  NEXT_PUBLIC_OAUTH_GATEWAY_URL: process.env.NEXT_PUBLIC_OAUTH_GATEWAY_URL,
  NEXT_PUBLIC_AI_ROUTE: process.env.NEXT_PUBLIC_AI_ROUTE,
};
