"use client";

import useCatalog from "#/hooks/data/catalog/use-catalog";
import { useAIDescription } from "#/hooks/use-ai-description";
import { useTStream } from "#/hooks/use-t-stream";
import { analytics, events } from "#/lib/analytics";
import { Button } from "#/ui/button";
import Loader from "#/ui/standard-loader";
import { SVGIcon } from "#/ui/svg-icon";
import { Textarea } from "#/ui/textinput";
import clsx from "clsx";
import { RefreshCcw, StarIcon } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

export const AIDescriptionInput = ({ onChangeItem, promptData, value }) => {
  const { data: catalog } = useCatalog();
  const { t } = useTStream("common");
  const [original, setOriginal] = useState(value);
  const [animating, setAnimating] = useState(false);
  const [hoveredStar, setHoveredStar] = useState(0);
  const [selectedStar, setSelectedStar] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const toggleAnimate = useCallback(() => setAnimating((prev) => !prev), []);
  const { submit, loading } = useAIDescription();

  const promptText = useMemo(() => {
    const modifier_groups = promptData?.modifierGroups?.map((mg) => ({
      name: mg?.name,
      modifiers: mg?.modifier_ids?.map((m) => {
        const modifier = catalog?.modifier?.find((m2) => m2?._id === m);
        return {
          name: modifier?.name,
        };
      }),
    }));
    return {
      modifier_groups,
      vibe: "",
      item_name: promptData?.name,
    };
  }, [promptData, catalog?.modifier]);

  const onBeforeChange = useCallback(
    (e) => {
      onChangeItem(e);
    },
    [onChangeItem],
  );

  const resetStars = useCallback(() => {
    setSelectedStar(0);
    setHoveredStar(0);
  }, [setSelectedStar, setHoveredStar]);

  const onDeny = useCallback(() => {
    onChangeItem(original);
    analytics.track(events.AI_DESCRIPTION_REJECTED, {
      rating: selectedStar,
    });
    resetStars();
    setSubmitted(false);
  }, [original, onChangeItem, selectedStar, resetStars]);

  const onAccept = useCallback(() => {
    setOriginal(value);
    analytics.track(events.AI_DESCRIPTION_GENERATED, {
      rating: selectedStar,
    });
    resetStars();
    setSubmitted(false);
  }, [value, resetStars, selectedStar]);

  const onSubmit = useCallback(async () => {
    await submit(promptText, onChangeItem);
    setSubmitted(true);
  }, [promptText, submit, onChangeItem]);

  return (
    <div className="relative">
      <Textarea
        title={t("Description")}
        placeholder="Briefly describe what kind of description you want 💬"
        className="rounded-xl mt-5 min-h-[10rem] border-[1px] border-border"
        value={value}
        onFocus={toggleAnimate}
        onBlur={toggleAnimate}
        onChange={onBeforeChange}
      />
      {submitted ? (
        <>
          <div className="flex flex-col absolute bottom-2 left-2 gap-2">
            <div className="flex">
              {[1, 2, 3, 4, 5].map((star) => (
                <Button
                  key={star}
                  variant="ghost"
                  className="h-8 rounded-full text-primary transition-colors duration-300"
                  size="icon"
                  onMouseEnter={() => setHoveredStar(star)}
                  onMouseLeave={() => setHoveredStar(0)}
                  onClick={() => setSelectedStar(star)}
                >
                  <StarIcon
                    className={
                      star <= hoveredStar || star <= selectedStar
                        ? "fill-primary transition-fill duration-300"
                        : ""
                    }
                  />
                </Button>
              ))}
            </div>
          </div>
          <div className="flex absolute bottom-2 right-2 gap-2">
            <Button
              variant="destructive-outline"
              className="h-8 rounded-full"
              onClick={onDeny}
            >
              {t("Deny")}
            </Button>
            <Button onClick={onAccept} className="h-8 rounded-full">
              {t("Accept")}
            </Button>
          </div>
        </>
      ) : (
        <Button
          className={clsx("absolute right-4 bottom-3", {
            "animate-pulse": animating,
          })}
          onClick={onSubmit}
        >
          {t(loading ? "Loading..." : "Generate using AI")}
        </Button>
      )}
    </div>
  );
};

export const AIBulkDescriptionInput = ({
  onChangeItem,
  className,
  bulkLoading,
  prompt,
  value,
  containerClassName = "",
}) => {
  const { t } = useTStream("common");
  const [original] = useState(value);
  const { submit, loading } = useAIDescription();

  const onReset = useCallback(() => {
    onChangeItem(original);
  }, [original, onChangeItem]);

  const onSubmit = useCallback(async () => {
    await submit(prompt, onChangeItem);
  }, [prompt, submit, onChangeItem]);

  return (
    <div className={containerClassName}>
      <Textarea
        placeholder="Use AI to create a description.."
        className={className}
        value={value}
        onChange={onChangeItem}
      />
      <div className="flex items-center justify-end space-x-3">
        <Button
          onClick={onReset}
          variant="ghost"
          disabled={bulkLoading}
          className="text-muted"
          size="xs"
        >
          <RefreshCcw className="h-4 w-4 mr-2 text-muted" />
          {t("Reset")}
        </Button>
        <Button
          onClick={onSubmit}
          disabled={bulkLoading}
          variant="ghost"
          className="text-muted"
          size="xs"
        >
          {loading || bulkLoading ? (
            <Loader size={16} className="mr-3" />
          ) : (
            <SVGIcon svg="magic" className="h-5 w-5 mr-2 text-muted" />
          )}
          {t("Generate")}
        </Button>
      </div>
    </div>
  );
};
