import * as React from "react";

import { cn } from "#/lib/utils";
import { twMerge } from "tailwind-merge";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, title, ...props }, ref) => {
    return (
      <>
        <label
          className={twMerge(
            "bg-card absolute left-[0.9rem] text-muted text-sm font-circular-std font-500 top-[-11px] z-[5]",
          )}
        >
          {title}
        </label>
        <textarea
          className={cn(
            "flex min-h-[80px] w-full rounded-xl border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          ref={ref}
          {...props}
        />
      </>
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
