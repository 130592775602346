import useLocations from "#/hooks/data/pos/use-locations";
import { resyncMutation } from "#/lib/atoms/mutations";
import { queries } from "#/lib/atoms/queries";
import { fetchRequest, userRequestContext } from "#/lib/stream-api";
import { components } from "#/types.ts/swagger";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";

export const useCatalogQuery = () => {
  const { currentLocation } = useLocations();
  const queryClient = useQueryClient();
  const [{ data: head }] = useAtom(queries.catalogHead);

  return useQuery<components["schemas"]["GetLocationCatalogResponseDto"]>({
    enabled: !!head,
    queryKey: ["catalog", currentLocation?._id, head],
    queryFn: async ({ queryKey: [_, location_id] }) => {
      /** these if conditions are here becauase `enabled` has no effect when using queryClient.suspense */
      if (!location_id) return {};
      try {
        return await fetchRequest(`/catalog?location_id=${location_id}`, {
          headers: {
            ...userRequestContext(),
            "x-cache-buster": head?.toString(),
          },
        });
      } catch (error) {
        console.log(error.message);
        if (error.message === "no catalog for location") {
          // idk if this is best place for it but suspense making it real hard to correlate error between queries/mutations
          return resyncMutation(currentLocation, null).then(() => {
            if (!location_id) return null;
            return queryClient
              .refetchQueries({
                queryKey: ["menu"],
                type: "active",
              })
              .then(() => {
                return fetchRequest(`/catalog?location_id=${location_id}`, {
                  headers: {
                    ...userRequestContext(),
                    "x-cache-buster": head?.toString(),
                  },
                });
              });
          });
        } else {
          throw error;
        }
      }
    },
  });
};
