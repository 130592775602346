import { env } from "#/env/client.mjs";
import { useCallback, useState } from "react";
import PQueue from "p-queue";
import { readDataStream } from "ai";

async function performAIFetch(
  prompt: Record<string, string>,
  callback: (val: string) => void,
) {
  const response = await fetch(env.NEXT_PUBLIC_AI_ROUTE || "/api/aiv2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prompt: JSON.stringify(prompt),
    }),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  if (!response.body) {
    /** This seems to be bedrock timeout when it happens */
    return callback("");
  }

  let prev = "";
  for await (const { type, value } of readDataStream(
    response.body.getReader(),
  )) {
    if (type === "text") {
      prev += value.replace(/"/g, "");
    }

    callback(prev);
  }
}

/** Establish AI pQueue */
const aiQueue = new PQueue({ concurrency: 3 });

export const useAIDescription = () => {
  const [loading, setLoading] = useState(false);
  const submit = useCallback(async (prompt, callback) => {
    setLoading(true);
    try {
      await aiQueue.add(() => performAIFetch(prompt, callback));
    } catch (err) {
      /** Toast happens above this */
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return { submit, loading };
};
